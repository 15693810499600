import React from "react";

const Loader = ({ color }) => {
  return (
    <div id="app-loader" className="app-loader">
      <div
        id="spinner"
        className={`app-loader__spinner ${
          color ? "app-loader__spinner-color" : "app-loader__spinner-color"
        }`}
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
